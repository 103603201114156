import { render } from '@testing-library/react';
import { post } from 'aws-amplify/api';
//Includes getThreads, getMessages from threads, render messages


//Rendering
export const renderThreads = (isSendDisabled, threads, currentThreadID, setCurrentThreadID, currentThreadArchived, setCurrentThreadArchived) => {
    // Sort threads by last_run_created_at
    threads.sort((a, b) => {
        const dateA = a[1].last_run_created_at || 0;
        const dateB = b[1].last_run_created_at || 0;
        return dateB - dateA;
    });

    const recentThreads = threads.filter(([threadID, threadData]) => threadData.archived === false);
    const archivedThreads = threads.filter(([threadID, threadData]) => threadData.archived === true);
    const renderThreadButton = (threadID, threadData) => {
        return (
            <button key={threadID} className={`button long ${currentThreadID === threadID ? "active" : ""} ${threadData.archived ? "archived" : ""}`} onClick={() => { if (!(isSendDisabled && !currentThreadArchived)) { setCurrentThreadID(threadID); setCurrentThreadArchived(threadData.archived) }; }}>
                <i className="material-icons-outlined">comment</i>
                <div className="label-container">
                    <span className={`label`}>
                        {threadData.summary}
                    </span>
                    <span className={`subtitle`}>
                        {threadData.last_run_created_at ? new Date(threadData.last_run_created_at * 1000).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }) : "No date"}
                    </span>
                </div>
                {/* <DeleteOutline className="end-icon" onClick={() => handleDeleteThread(jwtToken, threadID, setThreads)} /> */}
            </button>
        )
    };

    return (
        <div className="section column scroll">
            {recentThreads.map(([threadID, threadData]) => renderThreadButton(threadID, threadData))}
            {archivedThreads.length > 0 && (
                <>
                    <div className="section-header"> Archived Threads </div>
                    {archivedThreads.map(([threadID, threadData]) => renderThreadButton(threadID, threadData, true))}
                </>
            )}
        </div>
    );
};


//API Calls
export const getAllThreads = async (jwtToken) => {
    //runs on page load to get all file names
    try {
        const responseBody = await (await post({
            apiName: 'MaesterV3API',
            path: '/api/v3/ask',
            options: {
                headers: { Authorization: jwtToken },
                body: {
                    give: "me threads" //need a body for the post request, could be anything
                }
            }
        }).response).body.json();
        return responseBody;
    } catch (error) {
        console.error('Error getting threads:', error);
        return ({});
    };
}

export const getMessages = async (jwtToken, setSnackbar, thread_id) => {
    //runs on page load to get all messages
    try {
        setSnackbar("loading messages for thread...", "loading");
        const responseBody = await (await post({
            apiName: 'MaesterV3API',
            path: '/api/v3/ask',
            options: {
                headers: { Authorization: jwtToken },
                body: {
                    thread_id: thread_id
                }
            }
        }).response).body.json();
        setSnackbar("messages loaded!", "complete");
        console.log(responseBody);
        return responseBody;
    } catch (error) {
        setSnackbar('Error getting messages:', "error");
        return { "thread_id": thread_id, "messages": [] };
    }
};


// export const handleDeleteThread = async (jwtToken, thread, setThreads) => {
//     console.log("deleted thread", thread)
//     return ("deleted thread");
// }