import React, { useState, useEffect } from 'react';
import { Amplify } from "aws-amplify";
import { fetchAuthSession, fetchUserAttributes } from "aws-amplify/auth";
// import { withAuthenticator } from '@aws-amplify/ui-react';
import { BrowserRouter as Router, Route, Routes, useRoutes } from 'react-router-dom';
// import '@aws-amplify/ui-react/styles.css';

import "../styles/App/App.css";

import Chat from './Chat';
import Login from './LoginFlow/Login';
import ForgotPassword from './LoginFlow/ForgotPassword';
import InputCode from './LoginFlow/InputCode';
import ChangePassword from './LoginFlow/ChangePassword';
import FirstSignIn from './LoginFlow/FirstSignIn';
import Privacy from './Privacy';
import LandingPage from './LandingPage';
import NotFound from './NotFound';
import NotLoggedIn from './NotLoggedIn';
// import Onboarding from './Onboarding';
// import RequireApprovedUser from "./RequireApprovedUser";
import { HubspotCallbackHandler, GoogleCallbackHandler } from "../components/authHandlers";

const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID;
const USER_POOL_CLIENT_ID = process.env.REACT_APP_USER_POOL_CLIENT_ID;
const REST_ENDPOINT = process.env.REACT_APP_REST_ENDPOINT;

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: USER_POOL_ID,
            userPoolClientId: USER_POOL_CLIENT_ID,
            loginWith: {
                email: true
            },
        }
    },
    API: {
        REST: {
            MaesterV3API: {
                endpoint: REST_ENDPOINT,
                region: 'us-east-1'
            }
        }
    }
});

function useGetUserInfo() {
    const [user, setUser] = useState(null);
    const [userAttributes, setUserAttributes] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const checkUser = async () => {
        try {
            const userData = await fetchAuthSession();
            const userAttributeData = await fetchUserAttributes();
            // console.log('User:', userData);
            // console.log('User Attributes:', userAttributeData);
            if (!userData.tokens) {
                setIsAuthenticated(false);
            } else {
                setUser(userData);
                setUserAttributes(userAttributeData);
                setIsAuthenticated(true);
            }
        } catch (error) {
            console.log('User is not signed in');
            setIsAuthenticated(false); // Set authenticated to false on error
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        checkUser();
    }, []);

    return { user, isLoading, isAuthenticated, userAttributes };
}

function AuthenticatedApp() {
    let organization, email, username, role;

    const { user, isLoading, userAttributes } = useGetUserInfo();

    useEffect(() => {
        if (organization) {
            window.location.reload();
        }
    }, [organization]);

    if (user) {
        organization = userAttributes['custom:organization_name'] ?? null;
        // is_approved = user.attributes?.['custom:approved'] ?? 'false';
        email = userAttributes['email'] ?? null;
        username = userAttributes['custom:name'] ?? 'User';
        role = userAttributes['custom:role']?.toLowerCase() ?? 'basic';
    }
    // console.log('User Attributes:', userAttributes);

    let element;
    // if (organization) {
    //     if (is_approved === 'true') {
    //         element = <RequireApprovedUser><Chat user={{ organization, is_approved, email, username, is_user_admin }} /></RequireApprovedUser>;
    //     } else {
    //         element = "Sorry, you are not approved to use this app. Please ask the site administrators or your organization's Admins to approve you.";
    //     }
    // } else {
    //     element = <Onboarding />;
    // }
    if (organization && username) {
        element = <Chat user={{ organization, email, username, role }} />;
    } else {
        element = <NotLoggedIn/>;
    }

    let routes = useRoutes([
        { path: '/*', element: element },
    ]);
    
    if (isLoading) {
        return <div>Loading...</div>;
    }
    
    return (
        <div className="App">
            {routes}
        </div>
    );
}

// const AuthApp = withAuthenticator(AuthenticatedApp, { hideSignUp: true });

function App() {
    const { isAuthenticated, isLoading, userAttributes } = useGetUserInfo();

    return (
        <Router>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                {/* TODO, maybe try to figure this out to stop the "code" issue <Route path="/amplify-callback" element={<AmplifyCallbackHandler />} /> */}
                <Route path="/hubspot-callback" element={<HubspotCallbackHandler />} />
                <Route path="/google-callback" element={<GoogleCallbackHandler />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/login" element={<Login />} />
                <Route path="/verify" element={<InputCode />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/create-password" element={<FirstSignIn />} />
                <Route path="/chat/*" element={<AuthenticatedApp />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
}

export default App;